import axios from 'axios';
import moment from 'moment';
import {
	IFormCompanyInfo,
	IFormIndividualInfo,
	IFormPaymentInfo,
} from '../../../types/interfaces/IForms';
import { IPage, IPageOptions } from '../../../types/interfaces/IPage';
import { IUBO } from '../../../types/interfaces/IUBO';
import { TDocumentType } from '../../../types/types/TUser';
import API from '../../../utils/api';
import Axios from '../axios';
import stelace from '../stelace';
import IAPIUser, { TSSOProvider } from './types/IAPIUser';
import * as Options from './types/IOptions';
import * as Response from './types/IResponse';
import { useQueryClient } from 'react-query';
import { queryClient } from '../../../pages/_app';
import { IAddress } from '../../../types/interfaces/IAddress';
export default class APIUser implements IAPIUser {
	private http: Axios;

	constructor(http: Axios) {
		this.http = http;
	}

	///////////////////
	///    CRUD     ///
	//////////////////

	public async create(
		email: string,
		password: string,
		options?: Options.IUser,
	): Promise<Response.IUser> {
		let createdUser = await stelace.users.create({
			username: email,
			password: password,
			email: email,
			...options,
		});
		return createdUser;
	}

	public async get(id: string | null): Promise<Response.IUser | null> {
		if (id) {
			let user = await stelace.users.read(id);

			return {
				id: user.id,
				username: user.email,
				firstname: user.firstname,
				lastname: user.lastname,
				displayName: user.displayName,
				description: user.description,
				platformData: user.platformData,
				...user,
				metadata: {
					...user.metadata,
				},
			};
		}
		return null;
		// let user = await this.http.get('/user/info', {
		// 	id,
		// });
		// return user;
	}

	public async update(
		id: string,
		options?: Options.IUser,
	): Promise<Response.IUser | null> {
		// // // //
		let user = await this.updateUser(id, options);
		return user || null;
	}

	public async listUsers(
		pageOptions: IPageOptions,
		options?: Options.IUser,
	): Promise<IPage<Response.IUser> | undefined> {
		let listOrders: Response.IUser[] = await stelace.users.list({
			page: pageOptions.cursor,
			nbResultsPerPage: pageOptions?.limit,
			...options,
		});

		return listOrders.length > 0
			? {
					items: listOrders,
					nextCursor: pageOptions.cursor + 1,
					pageNumber: pageOptions.cursor,
			  }
			: undefined;
	}

	public async addToWishlist(
		userId: string,
		productId: string,
	): Promise<string[]> {
		let user: Response.IUser = await stelace.users.read(userId);
		let exWishlist = user.metadata?.wishlist || [];
		let add: Response.IUser | undefined = await this.updateUser(userId, {
			metadata: { wishlist: [...new Set([...exWishlist, productId])] },
		});
		return add?.metadata?.wishlist || [];
	}

	public async removeToWishlist(
		userId: string,
		productId: string,
	): Promise<string[]> {
		let user: Response.IUser = await stelace.users.read(userId);
		let exWishlistIndex = user.metadata?.wishlist?.findIndex(
			prod => prod === productId,
		);

		let wishlist = user.metadata?.wishlist;
		if (typeof exWishlistIndex === 'number' && wishlist) {
			wishlist.splice(exWishlistIndex, 1);
		}

		let newWishlist: string[] | undefined;

		let remove: Response.IUser | undefined = await this.updateUser(userId, {
			metadata: {
				wishlist: wishlist,
			},
		});

		newWishlist = remove?.metadata?.wishlist;

		return newWishlist || user.metadata?.wishlist || [];
	}

	public async getPresignedUrl(file: {
		name: string;
		type: string;
		path?: string;
	}): Promise<{
		method: 'POST' | 'GET';
		url: string;
		fields: {};
		headers: { 'Content-Type': string };
	}> {
		let url = await stelace.forward.post(
			process.env.NEXT_PUBLIC_FRONTEND + '/api/generatePresignedImage',
			{
				fileName: file.name,
				fileType: file.type,
				path: file.path,
			},
		);

		return url;
	}

	public async updateProfileViewCount(userId:string):Promise<void>{
		await API.Shop.createEvent('user_updated_view_count', { objectId: userId });
	}

	public async toggleHolidayMode(
		userId: string,
		pageOptions: IPageOptions,
		active?: boolean,
	): Promise<boolean> {
		const listProductUser = await API.Product.list(
			{ cursor: pageOptions.cursor, limit: 100 },
			{ ownerId: userId, quantity: { gte: 1 } },
		);

		if (listProductUser?.items) {
			for (let product of listProductUser.items) {
				await API.Product.update(product.id, {
					active: active,
				});
			}
		}

		const paginationMeta = listProductUser?.items.paginationMeta;
		if (paginationMeta) {
			if (paginationMeta?.nbPages > paginationMeta?.page) {
				await this.toggleHolidayMode(
					userId,
					{
						cursor: ++pageOptions.cursor,
					},
					active,
				);
			} else {
				const userHoliday = (await API.User.get(userId))?.metadata
					?.holiday;
				await API.User.update(userId, {
					metadata: {
						holiday: !userHoliday,
					},
				});
			}
		}

		return true;
	}

	public async delete(userId: string): Promise<{ id: string }> {
		return await stelace.users.remove(userId);
	}

	///////////////////
	///  MANGOPAY   ///
	//////////////////

	public async createBankAccount(
		userId: string,
		bankAccount: Options.IBankAccount,
	): Promise<Response.IBankAccount | undefined> {
		let mangopayUserId = await this.getMangoPayId(userId, 'owner');
		if (mangopayUserId) {
			let creation = await stelace.forward.post(
				'/integrations/mangopay/request',
				{
					method: 'Users.createBankAccount',
					args: [mangopayUserId, { Type: 'IBAN', ...bankAccount }],
				},
			);

			await API.Shop.createEvent('kyc_payout', { objectId: userId });
			return creation;
		}
		return undefined;
	}

	public async createOwnerUser(
		userId: string,
		options: (IFormIndividualInfo | IFormCompanyInfo) & {
			address: IAddress;
		},
	): Promise<{ Id: string; Wallet: string }> {
		console.log(userId, options);
		let createOwnerUser: { Id: string; Wallet: string } =
			await stelace.forward.post('/integrations/mangopay/request', {
				method: 'Custom.createOwnerUser',
				args: [{ userId, ...options }],
			});
		return createOwnerUser;
	}

	public async sendDocument(
		userId: string,
		document: {
			files: Blob[];
			type: TDocumentType;
		},
	): Promise<Response.IDocument | undefined> {
		let mangopayUserId = await this.getMangoPayId(userId);
		if (mangopayUserId) {
			let createDocument: Response.IDocument = await stelace.forward.post(
				'/integrations/mangopay/request',
				{
					method: 'Users.createKycDocument',
					args: [
						mangopayUserId,
						{ Type: document.type, Tag: userId },
					],
				},
			);

			let createdDocumentId = createDocument.Id;

			for (let page of document.files) {
				//let pageUrl = URL.createObjectURL(page);

				let createPage = await stelace.forward.post(
					'/integrations/mangopay/request',
					{
						method: 'Users.createKycPage',
						args: [
							mangopayUserId,
							createdDocumentId,
							{ File: page },
						],
					},
				);

				//URL.revokeObjectURL(pageUrl);
			}

			// Change status to VALIDATION_ASKED to submit the KYC Document
			await stelace.forward.post('/integrations/mangopay/request', {
				method: 'Users.updateKycDocument',
				args: [
					mangopayUserId,
					{ ...createDocument, Status: 'VALIDATION_ASKED' },
				],
			});

			await API.Shop.createEvent('kyc_documents', { objectId: userId });

			return createDocument;
		}
		return undefined;
	}

	public async sendUBODeclaration(
		userId: string,
		ubos: IUBO[],
	): Promise<Response.IUBODeclaration> {
		let mangopayUserId = await this.getMangoPayId(userId, 'owner');
		let user = await this.get(userId);
		let ubosDeclarationId =
			user?.platformData?._private?.mangoPay?.owner?.uboDeclaration?.id;

		if (!ubosDeclarationId) {
			ubosDeclarationId = (
				await stelace.forward.post('/integrations/mangopay/request', {
					method: 'UboDeclarations.create',
					args: [mangopayUserId],
				})
			).Id;
		}

		let uboIds = [];
		if (ubosDeclarationId) {
			for (let ubo of ubos) {
				uboIds.push(
					(
						await stelace.forward.post(
							'/integrations/mangopay/request',
							{
								method: 'UboDeclarations.createUbo',
								args: [mangopayUserId, ubosDeclarationId, ubo],
							},
						)
					).Id,
				);
			}
		}

		let uboDeclaration = await stelace.forward.post(
			'/integrations/mangopay/request',
			{
				method: 'UboDeclarations.get',
				args: [mangopayUserId, ubosDeclarationId],
			},
		);

		// Change status to VALIDATION_ASKED to submit the KYC Document
		await stelace.forward.post('/integrations/mangopay/request', {
			method: 'UboDeclarations.update',
			args: [
				mangopayUserId,
				{ ...uboDeclaration, Status: 'VALIDATION_ASKED' },
			],
		});

		await API.Shop.createEvent('kyc_ubos', { objectId: userId });

		return uboDeclaration;
	}

	public async registerCard(
		userId: string,
		cardInfo: IFormPaymentInfo,
		options?: {
			mongoUserType?: 'owner' | 'payer';
		},
	): Promise<Response.ICardRegistration | null> {
		let mangopayUserId = await this.getMangoPayId(
			userId,
			options?.mongoUserType || 'payer',
		);

		let registerCard = await stelace.forward.post(
			'/integrations/mangopay/request',
			{
				method: 'CardRegistrations.create',
				args: [{ UserId: mangopayUserId, Currency: 'EUR' }],
			},
		);

		let registrationURL = registerCard.CardRegistrationURL;
		const cardParams = new URLSearchParams();
		cardParams.append('accessKeyRef', registerCard.AccessKey);
		cardParams.append('data', registerCard.PreregistrationData);
		cardParams.append(
			'cardNumber',
			cardInfo.cardNumber.replaceAll(' ', ''),
		);
		cardParams.append(
			'cardExpirationDate',
			moment(cardInfo.dueDate).format('MMYY'),
		);
		cardParams.append('cardCvx', cardInfo.cvc);

		let postCardInfo = await axios.post(registrationURL, cardParams);

		if (postCardInfo.data.includes('errorCode')) {
			throw new Error(
				postCardInfo.data.substring(postCardInfo.data.indexOf('=') + 1),
			);
		} else if (postCardInfo.data.includes('data')) {
			let registrationData = postCardInfo.data;
			let updateCard = await stelace.forward.post(
				'/integrations/mangopay/request',
				{
					method: 'CardRegistrations.update',
					args: [
						{
							Id: registerCard.Id,
							UserId: mangopayUserId,
							RegistrationData: registrationData,
						},
					],
				},
			);

			return updateCard;
		}
		return null;
	}

	public async updateCard(
		userId: string,
		data: Options.ICard,
		options?: {
			mongoUserType?: 'owner' | 'payer';
		},
	): Promise<Response.ICard> {
		let mangopayUserId = await this.getMangoPayId(
			userId,
			options?.mongoUserType || 'payer',
		);
		let updateCard = await stelace.forward.post(
			'/integrations/mangopay/request',
			{
				method: 'Cards.update',
				args: [
					{
						UserId: mangopayUserId,
						...data,
					},
				],
			},
		);
		return updateCard;
	}

	public async getWallet(walletId: string): Promise<Response.IWallet> {
		return await stelace.forward.post('/integrations/mangopay/request', {
			method: 'Wallets.get',
			args: [walletId],
		});
	}

	public async payout(userId: string): Promise<Response.IPayout | undefined> {
		const user = await API.User.get(userId);
		const userWalletId =
			user?.platformData?._private?.mangoPay?.owner?.walletId;
		const userMangopayId =
			user?.platformData?._private?.mangoPay?.owner?.id;
		const userBankAccount =
			user?.platformData?._private?.mangoPay?.owner?.bankAccountId;

		if (userWalletId && userMangopayId && userBankAccount) {
			const wallet = await this.getWallet(userWalletId);

			const availableBalance = wallet.Balance.Amount;

			const payout = await stelace.forward.post(
				'/integrations/mangopay/request',
				{
					method: 'PayOuts.create',
					args: [
						{
							AuthorId: userMangopayId.toString(),
							PaymentType: 'BANK_WIRE',
							DebitedWalletId: userWalletId,
							BankAccountId: userBankAccount.toString(),
							DebitedFunds: {
								Currency: 'EUR',
								Amount: availableBalance,
							},
							Tag: userId,
							Fees: {
								Currency: 'EUR',
								Amount: 0,
							},
						},
					],
				},
			);

			return payout;
		}

		return undefined;
	}

	///////////////////
	///    AUTH    ///
	//////////////////

	public async login(
		username: string,
		password: string,
		loginFn?: () => void,
	): Promise<Response.ITokens> {
		let loginUser = await stelace.auth.login({
			username: username,
			password: password,
		});

		loginFn && loginFn();

		return loginUser;
	}

	public async logout(logoutFn?: () => void): Promise<Response.ILogout> {
		logoutFn && (await logoutFn());
		return await stelace.auth.logout();
	}

	public async resetPassword(email: string): Promise<boolean> {
		const requestResetPassword = await stelace.password.resetRequest({
			username: email,
		});

		return true;
	}

	public async confirmPassword(
		token: string,
		newPassword: string,
	): Promise<boolean> {
		const confirmNewPassword = await stelace.password.resetConfirm({
			resetToken: token,
			newPassword: newPassword,
		});

		return true;
	}

	///////////////////
	///  SSO AUTH   ///
	//////////////////

	public async getLoginLinkSSO(provider: TSSOProvider) {
		return `${
			process.env.NEXT_PUBLIC_BACKEND
		}/auth/sso/${await this.getPlatformPublicId()}/${provider}`;
	}

	public async getTokenSSO(token: string): Promise<Response.ITokens> {
		const grantType = 'authorizationCode';
		return await stelace.auth.getTokens({ token, grantType });
	}

	///////////////////
	///   CHECKER   ///
	//////////////////

	public async check(): Promise<Response.ICheck> {
		let check = await stelace.auth.check({
			apiKey: process.env.NEXT_PUBLIC_BACKEND_PUBLIC_API_KEYS,
		});

		return {
			valid: check.valid,
			user: check.user,
		};
	}

	public async sessionInfo(): Promise<Response.ISessionInfo> {
		let sessionInfo = await stelace.auth.info();
		return sessionInfo;
	}

	public async userToken(): Promise<string> {
		const tokenStore = await stelace.getTokenStore();
		const tokens = tokenStore.getTokens();

		return tokens && tokens.accessToken;
	}

	///////////////////
	///   REVIEWS   ///
	//////////////////

	public async getReviewStats(
		pageOptions: IPageOptions,
		options: Options.IReviewStats,
	): Promise<IPage<Response.IReviewStats> | undefined> {
		const reviewStats = await stelace.ratings.getStats({
			page: pageOptions.cursor,
			nbResultsPerPage: pageOptions.limit || 10,
			...options,
		});

		return reviewStats.length > 0
			? {
					items: reviewStats,
					nextCursor: pageOptions.cursor + 1,
					pageNumber: pageOptions.cursor,
			  }
			: undefined;
	}

	public async getReviews(
		pageOptions: IPageOptions,
		options: Options.IReview,
	): Promise<IPage<Response.IReview> | undefined> {
		const reviews = await stelace.ratings.list({
			page: pageOptions.cursor,
			nbResultsPerPage: pageOptions.limit || 10,
			...options,
		});

		return reviews.length > 0
			? {
					items: reviews,
					nextCursor: pageOptions.cursor + 1,
					pageNumber: pageOptions.cursor,
			  }
			: undefined;
	}

	public async createReview(
		authorId: string,
		targetUserId: string,
		options: Options.IReview,
	): Promise<Response.IReview> {
		const review = await stelace.ratings.create({
			authorId: authorId,
			targetId: targetUserId,
			...options,
		});
		return review;
	}

	public async updateReview(
		reviewId: string,
		options: Options.IReview,
	): Promise<Response.IReview> {
		const review = await stelace.ratings.update(reviewId, { ...options });
		return review;
	}

	public async deleteReview(reviewId: string): Promise<{ id: string }> {
		return await stelace.ratings.remove(reviewId);
	}

	///////////////////
	///    UTILS    ///
	//////////////////
	public areDocumentsInReview(user: Response.IUser): boolean {
		if (
			user.platformData?._private?.mangoPay?.owner?.kyc?.info &&
			user.platformData?._private?.mangoPay?.owner?.kyc?.documents &&
			user.platformData?._private?.mangoPay?.owner?.kyc?.payout
		) {
			if (user.platformData.userType === 'business') {
				if (user.platformData?._private?.mangoPay?.owner?.kyc?.ubos) {
					return true;
				} else return false;
			} else {
				return true;
			}
		}
		return false;
	}

	///////////////////
	///   PRIVATE   ///
	//////////////////

	private async getPlatformPublicId() {
		let check = await stelace.auth.check({
			apiKey: process.env.NEXT_PUBLIC_BACKEND_PUBLIC_API_KEYS,
		});

		let { zone, platformId, env } = check.apiKey;
		return `${zone}${platformId}_${env}`;
	}

	private async getMangoPayId(
		userId: string,
		type: 'owner' | 'payer' = 'owner',
	) {
		return type === 'owner'
			? (await this.get(userId))?.platformData?._private?.mangoPay?.owner
					?.id
			: (await this.get(userId))?.platformData?._private?.mangoPay?.payer
					?.id;
	}

	private async updateUser(
		id: string,
		options?: Options.IUser,
	): Promise<Response.IUser | undefined> {
		// // // //
		let user = await stelace.users.update(id, options);
		queryClient.invalidateQueries('user');
		queryClient.invalidateQueries('session');
		return user;
	}
}
